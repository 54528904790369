/* Change the color of the dots */
.slick-dots li button:before {
    font-size: 12px;
    color: #939DFA; /* Custom color for dots */
  }
  
  /* Change the color of the active dot */
  .slick-dots li.slick-active button:before {
    color: #4A5DFA; /* Custom color for active dot */
  }
  