@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-font-color: rgb(147, 157, 250);
  --primary-font-color-hover: rgb(82, 98, 245);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.slide-in-element {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  to {
      transform: translateX(0);
      opacity: 1;
  }
}
